import { IStudentResume } from "./../../../../shared/components/student-list-widget/models/IStudentResume";
import { IStudentDetails } from "./../../../../shared/components/student-list-widget/models/IStudentDetails";
import { IStudentReport } from "src/app/shared/services/student.service.models";
import { IDibelsReport } from "src/app/shared/services/student.service.models";
import { ChangeDetectorRef, Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IFaqGroup } from "src/app/shared/components/faq-section-widget/models/Faq";
import { StudentsService } from "src/app/shared/services/students.service";
import { ParentsService } from "src/app/shared/services/parents.service";
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import * as _ from "lodash";
import * as m from 'moment';
import { ProfileService } from "src/app/shared/services/profile.service";
import swal from 'sweetalert';
import { NotificationServices } from "src/app/shared/services/notification.service";
import { IStudentELAReport } from "../student-testing/models/StudentELAReport";
import { Chart, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { IStudentMathReport } from "./models/StudentMathReport";
import { IStudentReportGradeLevel } from "./models/StudentReportGradeLevel";
import annotationPlugin from 'chartjs-plugin-annotation';
import {StudenttestingdownloadService} from "src/app/shared/services/studenttestingdownload.service"
Chart.register(annotationPlugin);

// Custom plugin to add labels to the center of each bar
const centerLabelPlugin = {
  id: 'centerLabelPlugin',
  afterDatasetsDraw(chart: any) {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset: any, datasetIndex: number) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((bar: any, index: number) => {
        const value = dataset.data[index];

        // Skip drawing label if the value is 0 or NaN
        if (value === 0 || isNaN(value)) return;

        const centerX = bar.x;
        const centerY = bar.y + (bar.base - bar.y) / 2;

        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'black';
        ctx.fillText('+' + value, centerX, centerY);
        ctx.restore();
      });
    });
  }
};

Chart.register(centerLabelPlugin);

@Component({
  selector: "app-student-testing",
  templateUrl: "./student-testing.component.html",
  styleUrls: ["./student-testing.component.scss"],
})
export class StudentTestingComponent implements OnInit {
  private chart: Chart;
  reportId: string = "CAASPP";
  reportYear: string = null;
  academicYear: string = null;
  iReadyTest: string = null;
  iReadyTestType: string[] = ['Reading', 'Math'];
  dibelsAssessmentPeriod: string = null;
  dibelsAssessmentPeriodType: string[] = ['Beginning of Year', 'Middle of Year', 'End of Year'];
  studentId: string = null;
  reportType: string = null;
  reportResponse: IStudentReport = null;
  dibelsReport: IDibelsReport = null;
  message: any = '';
  student: IStudentResume = null;
  lastUpdated: any;
  pfts: any = null;
  olsat: any = null;
  ap: any = null;
  psat: any = null;
  iReadyELA: IStudentELAReport[] = [];
  iReadyMath: IStudentMathReport[] = [];
  iReadyBoyELA: IStudentELAReport = null;
  iReadyMoyELA: IStudentELAReport = null;
  iReadyEoyELA: IStudentELAReport = null;
  tempIReadyMoyELATest: IStudentELAReport[] = [];
  iReadyBoyMath: IStudentMathReport = null;
  iReadyMoyMath: IStudentMathReport = null;
  iReadyEoyMath: IStudentMathReport = null;
  tempIReadyMoyMathTest: IStudentMathReport[] = [];
  minScoreValueELA: number;
  maxScoreValueELA: number;
  minScoreValueMath: number;
  maxScoreValueMath: number;
  minGradeLevelELA: number;
  maxGradeLevelELA: number;
  midOnGradeLevelELA: number;
  minGradeLevelMath: number;
  maxGradeLevelMath: number;
  midOnGradeLevelMath: number;
  annualTypicalGrowthMeasureELA: number;
  annualStretchGrowthMeasureELA: number;
  annualTypicalGrowthMeasureMath: number;
  annualStretchGrowthMeasureMath: number;
  scoreRange: any;
  stepSize: any;
  showArrowELA: boolean = false;
  showSquareELA: boolean = false
  showDashELA: boolean = false;
  showArrowMath: boolean = false;
  showSquareMath: boolean = false
  showDashMath: boolean = false;
  latestMoyCompletionDate: Date;
  showiReadyReport: boolean = false;
  isResponseReceivedELA: boolean = null;
  isResponseReceivedMath: boolean = null;
  showGrowthLines: boolean = true;
  lexileMeasure: any;
  lexileRange: any;
  iReadyReportFlag: boolean = false;
  lowerMOYELA: boolean = false;
  lowerEOYELA: boolean = false;
  tempOverallScoreMOYELA: number;
  tempOverallScoreEOYELA: number;
  lowerMOYMath: boolean = false;
  lowerEOYMath: boolean = false;
  tempOverallScoreMOYMath: number;
  tempOverallScoreEOYMath: number;
  IsDownloadButtonVisible:boolean=false;
  englishLink: string = '';
  spanishLink: string = '';
  testNames = [
    { id: "CAASPP", desc: "", gradeMin: "PK", gradeMax: 12, name: "California Assessment of Student Performance and Progress" },
    { id: "ELPAC", desc: "", gradeMin: "PK", gradeMax: 12, name: "English Language Proficiency Assessments for California" },
    { id: "PFT", desc: "", gradeMin: "PK", gradeMax: 12, name: "Physical Fitness Test" },
    { id: "OLSAT", desc: "", gradeMin: "PK", gradeMax: 5, name: "Otis-Lennon School Ability Test" },
    { id: "AP", desc: "", gradeMin: 6, gradeMax: 12, name: "Advanced Placement" },
    { id: "DIBELS", desc: "", gradeMin: "PK", gradeMax: 5, name: "Dynamic Indicators of Basic Early Literacy Skills" },
    { id: "PSAT", desc: "", gradeMin: 6, gradeMax: 12, name: "Preliminary SAT" },
    { id: "iReady", desc: "", gradeMin: "PK", gradeMax: 12, name: "iReady Assessment" },
  ];
  years: any = [];

  //iReady Graph configuration
  public barChartData: ChartDataset[] = [
    { data: [], label: '', backgroundColor: 'rgba(54, 162, 235, 0.5)', hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)', barThickness: 60 },
    { data: [], label: '', backgroundColor: 'rgba(0, 100, 0, 0.5)', hoverBackgroundColor: 'rgba(0, 100, 0, 0.8)', barThickness: 60 },
  ];

  public barChartOptions: ChartOptions;
  public barChartLabels: string[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [annotationPlugin, centerLabelPlugin];

  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private parentService: ParentsService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private studenttestingdownload:StudenttestingdownloadService
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((data) => {
      console.log(data)
      if (data.reportType) {
        this.reportId = data.reportType
      }
    })
    this.activeRoute.parent.params.subscribe((data) => {
      this.studentId = data.studentId;
      this.lastUpdated = m();
    });
    this.student = this.studentService.currentStudent();
    console.log(this.student, 'student====')
    this.studentId = this.student.studentId;

    this.studentService.getStudentYears(this.studentId).subscribe(res => {
      _.each(res.yearList, year => {

        let value = year.slice(5, 9);

        let yearModel = { years: year, value: value }
        this.years.push(yearModel)
      })

      this.reportYear = this.years[0].value;
      this.academicYear = this.years[0];
    })

    //iReady Graph Configuration
    //Default to Reading
    this.checkIReadyFlag();
    this.iReadyTest = this.iReadyTestType[0];
    this.dibelsAssessmentPeriod = this.dibelsAssessmentPeriodType[0];

    this.startTour();
  }

  checkIReadyFlag(): void {
    this.parentService.getFeatureConfiguration().subscribe(
      (response: { name: string; value: string }[]) => {
        if (Array.isArray(response)) {
          const flagConfig = response.find(x => x.name === 'iready_report_flag');
          console.log("flagConfig: ", flagConfig);
          this.iReadyReportFlag = flagConfig ? flagConfig.value === 'Y' : false;
        } else {
          console.error('Unexpected response format:', response);
        }
      },
      (error) => {
        console.error('Error fetching feature configuration:', error);
      }
    );
    console.log("this.iReadyReportFlag ", this.iReadyReportFlag)
  }

  cleanResponse() {
    this.reportResponse = null;
    this.message = '';
  }

  hideiReadyReports() {
    this.showiReadyReport = false;
    this.isResponseReceivedELA = null;
    this.isResponseReceivedMath = null;
    this.showGrowthLines = true;
    this.IsDownloadButtonVisible=false;
  }

  resetReport() {
    // Reset min and max scores for the ELA report data
    this.iReadyBoyELA = null;
    this.iReadyMoyELA = null;
    this.iReadyEoyELA = null;
    this.tempIReadyMoyELATest = [];

    // Reset min and max scores for the ELA chart data   
    this.minScoreValueELA = 0;
    this.maxScoreValueELA = 0;
    this.annualTypicalGrowthMeasureELA = 0;
    this.annualStretchGrowthMeasureELA = 0;
    this.barChartData.forEach(dataset => dataset.data = []);
    this.barChartLabels = [];
    this.barChartData = [
      { data: [], label: '', backgroundColor: 'rgba(54, 162, 235, 0.5)', hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)', barThickness: 60 },
      { data: [], label: '', backgroundColor: 'rgba(0, 100, 0, 0.5)', hoverBackgroundColor: 'rgba(0, 100, 0, 0.8)', barThickness: 60 },
    ];

    // Reset min and max scores for the Math report data
    this.iReadyBoyMath = null;
    this.iReadyMoyMath = null;
    this.iReadyEoyMath = null;
    this.tempIReadyMoyMathTest = [];

    // Reset min and max scores for the Math chart data
    this.minScoreValueMath = 0;
    this.maxScoreValueMath = 0;
    this.annualTypicalGrowthMeasureMath = 0;
    this.annualStretchGrowthMeasureMath = 0;
    this.barChartData.forEach(dataset => dataset.data = []);
    this.barChartLabels = [];
    this.barChartData = [
      { data: [], label: '', backgroundColor: 'rgba(54, 162, 235, 0.5)', hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)', barThickness: 60 },
      { data: [], label: '', backgroundColor: 'rgba(0, 100, 0, 0.5)', hoverBackgroundColor: 'rgba(0, 100, 0, 0.8)', barThickness: 60 },
    ];
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
      {
        anchorId: 'start-tour',
        content: 'Your results from various tests will be displayed here',
        title: 'Student Testing',
        placement: right,
      },
      {
        anchorId: 'test0',
        content: 'Select a test by clicking on its title...',
        title: 'Step 1',
        placement: above,
      },
      {
        anchorId: 'year',
        content: 'Then select a year to download results from that grade year and click Download',
        title: 'Step 2',
        placement: below,
      },
    ]);
    if (this.profileService.profile.hasTour) {
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  ngOnChanges() {
    this.cdRef.detectChanges();
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }

  getReport() {
    this.cdRef.detectChanges();
    this.message = 'Please wait while we download your report';

    if (this.reportId == "PFT") {
      this.getPft();
    } else if (this.reportId == "OLSAT") {
      this.getOlsat();
    } else if (this.reportId == "AP") {
      this.getAp();
    } else if (this.reportId == "PSAT") {
      this.getPsat();
    } else if (this.reportId == "DIBELS") {
      this.getDibels();
    } else if (this.reportId == "iReady" && this.iReadyTest == 'Reading') {
      this.getIReadyELA();
      this.IsDownloadButtonVisible=true;
    } else if (this.reportId == "iReady" && this.iReadyTest == 'Math') {
      console.log(this.iReadyTest)
      this.getIReadyMath();
      this.IsDownloadButtonVisible=true;
    } else {
      let reportName = this.reportId;
      let reportFilter = this.reportId;
      if (this.reportId == "ELPAC") {
        reportName = "CAASPP"
        reportFilter = "ESA"
      }

      this.studentService
        .getStudentReport(this.studentId, reportName, this.reportYear)
        .subscribe((response) => {
          this.message = 'Download was successful. Click to view.'
          this.reportResponse = response;
          if (response)
            this.reportResponse.studentTestResource = this.reportResponse.studentTestResource.filter(r => r.type == reportFilter);
        }, error => { this.message = 'Please check your internet connection and retry.' });
    }
  }

  hasReport(report: IStudentReport): boolean {
    if (!report || !report.studentTestResource || report.studentTestResource.length === 0) {
      this.message = '';
      return false;
    }
    return true;
  }

  hasDibelsReport(report: IDibelsReport): boolean {
    if (!report || report.pdf === "") {
      return false;
    }
    return true;
  }


  hasOlsatReport(olsatReport) {
    if (olsatReport.years == null) return false
    else return true
  }

  testFilter(tests: any) {
    // Filter out iReady tests if the iReadyReportFlag is false
    const filteredTests = this.iReadyReportFlag ? tests : tests.filter(t => t.id !== 'iReady');

    return filteredTests.filter((t) => {
      if (this.student?.gradeLevel == "PK" || this.student?.gradeLevel == "K") {
        return t.gradeMin === "PK"
      } else {
        return (
          (this.student?.gradeLevel?.toString() === t.gradeMin.toString() ||
            this.student?.gradeLevel >= t.gradeMin ||
            (t.gradeMin === "PK" && this.student?.gradeLevel >= "1")) &&
          this.student?.gradeLevel <= t.gradeMax
        );
      }

    });
  }
  getPft() {
    this.studentService
      .getStudentPFTReport(this.studentId, this.reportYear)
      .subscribe((response) => {
        this.message = '';
        console.log(response)
        this.pfts = this.processResponse(response);
      });

  }
  getPsat() {
    this.studentService
      .getStudentPsatReport(this.studentId, this.reportYear)
      .subscribe((response) => {
        this.message = '';
        this.psat = response.years;
      });
  }
  getAp() {
    this.studentService
      .getStudentApReport(this.studentId, this.reportYear)
      .subscribe((response) => {
        this.message = '';
        this.ap = response.years;
      });
  }
  getDibels() {
    this.studentService
      .getDibelsReport(this.studentId, Number(this.reportYear), this.dibelsAssessmentPeriod)
      .subscribe((response) => {
        this.dibelsReport = response;
      }, error => { this.message = 'Please check your internet connection and retry.' });
  }

  getOlsat() {
    this.studentService
      .getStudentOlsatReport(this.studentId, this.reportYear)
      .subscribe((response) => {
        this.olsat = this.processOlsatResponse(response);
        if (this.olsat.years !== null) {
          this.olsat.year = this.olsat.years[0].schoolYear;
        }
        this.message = '';
      });
  }
  getIReadyELA() {
    this.generateAcademicYear();
    this.isResponseReceivedELA = false;

    this.studentService
      .getStudentIReadyELAReport(this.studentId, this.academicYear)
      .subscribe((response: IStudentELAReport[]) => {
        if (response && response.length > 0) {
          this.message = '';
          this.iReadyELA = response;
          console.log("ELA Response: " + response);
          this.processIReadyELAResponse(response);
          this.getLexile();
          this.isResponseReceivedELA = true;
        } else {
          this.isResponseReceivedELA = false;
          this.message = "";
          console.log("No ELA Response received.");
        }
      },
        error => {
          console.error("Error fetching ELA report: ", error);
          this.isResponseReceivedELA = false;
          this.message = "";
        });
  }

  getIReadyMath() {
    this.generateAcademicYear();
    this.isResponseReceivedMath = false;

    this.studentService
      .getStudentIReadyMathReport(this.studentId, this.academicYear)
      .subscribe(
        (response: IStudentMathReport[]) => {
          if (response && response.length > 0) {
            this.message = '';
            this.iReadyMath = response;
            console.log("Math Response: " + response);
            this.processIReadyMathResponse(response);
            this.isResponseReceivedMath = true;
          } else {
            this.isResponseReceivedMath = false;
            this.message = "";
            console.log("No Math Response received.");
          }
        },
        error => {
          console.error("Error fetching Math report: ", error);
          this.isResponseReceivedMath = false;
          this.message = "";
        }
      );
  }

  processIReadyELAResponse(response) {
    this.showiReadyReport = true;
    this.resetReport();

    response.forEach((item, index) => {
      console.log(`Object ${index}:`, item);
      if (index == 0) {
        this.englishLink = item.ireadyEnglishLink;
        this.spanishLink = item.ireadySpanishLink;
      }
      if (item.normingWindow.startsWith('Fall') && item.baselineDiagnostic === 'Y') {
        this.iReadyBoyELA = item;
        this.barChartData[0].data[0] = parseInt(item.overallScaleScore);
        this.barChartData[1].data[0] = parseInt(item.diagnosticGain);
        this.barChartLabels[0] = 'BOY (' + item.completionDate + ')'
      } else if (item.normingWindow.startsWith('Spring') && item.mostRecentDiagnosticYTD === 'Y') {
        this.iReadyEoyELA = item;
        this.barChartData[0].data[2] = parseInt(item.overallScaleScore) - parseInt(item.diagnosticGain);
        this.barChartData[1].data[2] = parseInt(item.diagnosticGain);
        this.barChartLabels[2] = 'EOY (' + item.completionDate + ')'
      } else if (item.normingWindow.startsWith('Winter')) {
        this.tempIReadyMoyELATest.push(item);
      }

      //Get the Stretch, Typical, and Mid On Grade Level values
      if (item.mostRecentDiagnosticYTD === 'Y') {
        if (item.annualTypicalGrowthMeasure) {
          this.annualTypicalGrowthMeasureELA = item.annualTypicalGrowthMeasure;
        }
        if (item.annualStretchGrowthMeasure) {
          this.annualStretchGrowthMeasureELA = item.annualStretchGrowthMeasure;
        }
      }
    });

    if (this.tempIReadyMoyELATest.length == 1) {
      this.iReadyMoyELA = this.tempIReadyMoyELATest[0];
      this.barChartData[0].data[1] = parseInt(this.tempIReadyMoyELATest[0].overallScaleScore) - parseInt(this.tempIReadyMoyELATest[0].diagnosticGain);
      this.barChartData[1].data[1] = parseInt(this.tempIReadyMoyELATest[0].diagnosticGain);
      this.barChartLabels[1] = 'MOY (' + this.tempIReadyMoyELATest[0].completionDate + ')'
    }
    else if (this.tempIReadyMoyELATest.length != 0) {
      let latestMoyTest: IStudentELAReport = null;
      this.tempIReadyMoyELATest.forEach((moy, index) => {
        console.log(`Moy at index ${index}:`, moy);

        if (!latestMoyTest || moy.completionDate > latestMoyTest.completionDate) {
          latestMoyTest = moy;
        }
      });

      this.iReadyMoyELA = latestMoyTest;
      this.barChartData[0].data[1] = parseInt(latestMoyTest.overallScaleScore) - parseInt(latestMoyTest.diagnosticGain);
      this.barChartData[1].data[1] = parseInt(latestMoyTest.diagnosticGain);
      this.barChartLabels[1] = 'MOY (' + latestMoyTest.completionDate + ')'
    }

    //Assign ordinal values to percentiles
    if (this.iReadyBoyELA && this.iReadyBoyELA.percentile != null) {
      this.iReadyBoyELA.percentile = this.getOrdinalPercentile(parseInt(this.iReadyBoyELA.percentile));
    }

    if (this.iReadyMoyELA && this.iReadyMoyELA.percentile != null) {
      this.iReadyMoyELA.percentile = this.getOrdinalPercentile(parseInt(this.iReadyMoyELA.percentile));
    }

    if (this.iReadyEoyELA && this.iReadyEoyELA.percentile != null) {
      this.iReadyEoyELA.percentile = this.getOrdinalPercentile(parseInt(this.iReadyEoyELA.percentile));
    }

    //Calculate Typical and Stretch Growth using the BOY
    if (this.annualTypicalGrowthMeasureELA) {
      this.annualTypicalGrowthMeasureELA = Number(this.annualTypicalGrowthMeasureELA) + Number(this.iReadyBoyELA.overallScaleScore);
    }

    if (this.annualStretchGrowthMeasureELA) {
      this.annualStretchGrowthMeasureELA = Number(this.annualStretchGrowthMeasureELA) + Number(this.iReadyBoyELA.overallScaleScore);
    }

    console.log("Typical Growth", this.annualTypicalGrowthMeasureELA);
    console.log("Stretch Growth", this.annualStretchGrowthMeasureELA);

    this.assignIReadyELAReportMinMaxValue();
    this.checkGrowthLineDisplayELA();

    //Check for cases where MOY or EOY are less than the minimum scale score
    if (this.isNumber(this.barChartData[0].data[1])) {
      if (this.barChartData[0].data[1] < this.minScoreValueELA) {
        this.tempOverallScoreMOYELA = this.barChartData[0].data[1];
        this.barChartData[0].data[1] = this.minScoreValueELA + 2;
        this.lowerMOYELA = true;
      }
    }

    if (this.isNumber(this.barChartData[0].data[2])) {
      if (this.barChartData[0].data[2] < this.minScoreValueELA) {
        this.tempOverallScoreEOYELA = this.barChartData[0].data[2];
        this.barChartData[0].data[2] = this.minScoreValueELA + 2;
        this.lowerEOYELA = true;
      }
    }

    this.getIReadyELAReportGradeLevel(this.iReadyBoyELA.grade);

    console.log("Completion Date", this.iReadyBoyELA?.completionDate);

    console.log("Boy ELA:", this.iReadyBoyELA);
    console.log("Eoy ELA:", this.iReadyEoyELA);
    console.log("Moy ELA:", this.iReadyMoyELA);
  }

  isNumber(value: any): value is number {
    return typeof value === 'number';
  }

  assignIReadyELAReportMinMaxValue() {
    this.minScoreValueELA = Number(this.iReadyBoyELA.overallScaleScore);
    this.minScoreValueELA = this.minScoreValueELA - 40;
    this.minScoreValueELA = Math.floor(this.minScoreValueELA / 10) * 10;
    this.maxScoreValueELA = this.minScoreValueELA + 120;

    console.log("Min score ELA: ", this.minScoreValueELA);
    console.log("Max score ELA: ", this.maxScoreValueELA);
  }

  generateELALegends() {
    if (this.minGradeLevelELA >= this.maxScoreValueELA) {
      this.showArrowELA = true;
      this.showSquareELA = false;
    }
    else {
      this.showArrowELA = false;
      this.showSquareELA = true;
    }

    if (this.midOnGradeLevelELA > this.maxScoreValueELA) {
      this.showDashELA = false;
    } else {
      this.showDashELA = true;
    }

    console.log("Show Arrow ELA: ", this.showArrowELA);
    console.log("Show Dash ELA:", this.showSquareELA);
    console.log("Show Dash ELA:", this.showDashELA);
  }

  generateMathLegends() {
    if (this.minGradeLevelMath >= this.maxScoreValueMath) {
      this.showArrowMath = true;
      this.showSquareMath = false;
    }
    else {
      this.showArrowMath = false;
      this.showSquareMath = true;
    }

    if (this.midOnGradeLevelMath > this.maxScoreValueMath) {
      this.showDashMath = false;
    } else {
      this.showDashMath = true;
    }

    console.log("Show Arrow Math: ", this.showArrowMath);
    console.log("Show Square Math:", this.showSquareMath);
    console.log("Show Dash Math:", this.showDashMath);
  }

  checkGrowthLineDisplayELA() {
    if ((this.annualTypicalGrowthMeasureELA > this.maxScoreValueELA || this.annualTypicalGrowthMeasureELA < this.minScoreValueELA) &&
      (this.annualStretchGrowthMeasureELA > this.maxScoreValueELA || this.annualStretchGrowthMeasureELA < this.minScoreValueELA)) {
      this.showGrowthLines = false;
    }
  }

  checkGrowthLineDisplayMath() {
    if ((this.annualTypicalGrowthMeasureMath > this.maxScoreValueMath || this.annualTypicalGrowthMeasureMath < this.minScoreValueMath) &&
      (this.annualStretchGrowthMeasureMath > this.maxScoreValueMath || this.annualStretchGrowthMeasureMath < this.minScoreValueMath)) {
      this.showGrowthLines = false;
    }
  }

  getLexile() {
    this.iReadyELA.forEach((latestTest, index) => {
      if (latestTest.mostRecentDiagnosticYTD === 'Y') {
        this.lexileMeasure = latestTest.lexileMeasure;
        this.lexileRange = latestTest.lexileRange
      }
    });
  }

  processIReadyMathResponse(response) {
    this.showiReadyReport = true;
    this.resetReport();

    response.forEach((item, index) => {
      console.log(`Object ${index}:`, item);
      if (index == 0) {
        console.log("Video links " + item)
        this.englishLink = item.ireadyEnglishLink;
        this.spanishLink = item.ireadySpanishLink;
      }

      if (item.normingWindow.startsWith('Fall') && item.baselineDiagnostic === 'Y') {
        this.iReadyBoyMath = item;
        this.barChartData[0].data[0] = parseInt(item.overallScaleScore);
        this.barChartData[1].data[0] = parseInt(item.diagnosticGain);
        this.barChartLabels[0] = 'BOY (' + item.completionDate + ')';
      } else if (item.normingWindow.startsWith('Spring') && item.mostRecentDiagnosticYTD === 'Y') {
        this.iReadyEoyMath = item;
        this.barChartData[0].data[2] = parseInt(item.overallScaleScore) - parseInt(item.diagnosticGain);
        this.barChartData[1].data[2] = parseInt(item.diagnosticGain);
        this.barChartLabels[2] = 'EOY (' + item.completionDate + ')';
      } else if (item.normingWindow.startsWith('Winter')) {
        this.tempIReadyMoyMathTest.push(item);
      }

      //Get the Stretch, Typical, and Mid On Grade Level values
      if (item.mostRecentDiagnosticYTD === 'Y') {
        if (item.annualTypicalGrowthMeasure) {
          this.annualTypicalGrowthMeasureMath = item.annualTypicalGrowthMeasure;
        }
        if (item.annualStretchGrowthMeasure) {
          this.annualStretchGrowthMeasureMath = item.annualStretchGrowthMeasure;
        }
      }
    });

    if (this.tempIReadyMoyMathTest.length == 1) {
      this.iReadyMoyMath = this.tempIReadyMoyMathTest[0];
      this.barChartData[0].data[1] = parseInt(this.tempIReadyMoyMathTest[0].overallScaleScore) - parseInt(this.tempIReadyMoyMathTest[0].diagnosticGain);
      this.barChartData[1].data[1] = parseInt(this.tempIReadyMoyMathTest[0].diagnosticGain);
      this.barChartLabels[1] = 'MOY (' + this.tempIReadyMoyMathTest[0].completionDate + ')';
    }
    else if (this.tempIReadyMoyMathTest.length != 0) {
      let latestMoyTest: IStudentMathReport = null;
      this.tempIReadyMoyMathTest.forEach((moy, index) => {
        console.log(`Math Moy at index ${index}:`, moy);

        if (!latestMoyTest || moy.completionDate > latestMoyTest.completionDate) {
          latestMoyTest = moy;
        }
      });

      this.iReadyMoyMath = latestMoyTest;
      this.barChartData[0].data[1] = parseInt(latestMoyTest.overallScaleScore) - parseInt(latestMoyTest.diagnosticGain)
      this.barChartData[1].data[1] = parseInt(latestMoyTest.diagnosticGain);
      this.barChartLabels[1] = 'MOY (' + latestMoyTest.completionDate + ')';

    }

    //Assign ordinal values to percentiles
    if (this.iReadyBoyMath && this.iReadyBoyMath.percentile != null) {
      this.iReadyBoyMath.percentile = this.getOrdinalPercentile(parseInt(this.iReadyBoyMath.percentile));
    }

    if (this.iReadyMoyMath && this.iReadyMoyMath.percentile != null) {
      this.iReadyMoyMath.percentile = this.getOrdinalPercentile(parseInt(this.iReadyMoyMath.percentile));
    }

    if (this.iReadyEoyMath && this.iReadyEoyMath.percentile != null) {
      this.iReadyEoyMath.percentile = this.getOrdinalPercentile(parseInt(this.iReadyEoyMath.percentile));
    }

    //Calculate Typical and Stretch Growth using the BOY
    if (this.annualTypicalGrowthMeasureMath) {
      this.annualTypicalGrowthMeasureMath = Number(this.annualTypicalGrowthMeasureMath) + Number(this.iReadyBoyMath.overallScaleScore);
    }

    if (this.annualStretchGrowthMeasureMath) {
      this.annualStretchGrowthMeasureMath = Number(this.annualStretchGrowthMeasureMath) + Number(this.iReadyBoyMath.overallScaleScore);
    }

    console.log("Typical Growth", this.annualTypicalGrowthMeasureMath);
    console.log("Stretch Growth", this.annualStretchGrowthMeasureMath);

    this.assignIReadyMathReportMinMaxValue();
    this.checkGrowthLineDisplayMath();

    //Check for cases where MOY or EOY are less than the minimum scale score
    if (this.isNumber(this.barChartData[0].data[1])) {
      if (this.barChartData[0].data[1] < this.minScoreValueMath) {
        this.tempOverallScoreMOYMath = this.barChartData[0].data[1];
        this.barChartData[0].data[1] = this.minScoreValueMath + 2;
        this.lowerMOYMath = true;
      }
    }

    if (this.isNumber(this.barChartData[0].data[2])) {
      if (this.barChartData[0].data[2] < this.minScoreValueMath) {
        this.tempOverallScoreEOYMath = this.barChartData[0].data[2];
        this.barChartData[0].data[2] = this.minScoreValueMath + 2;
        this.lowerEOYMath = true;
      }
    }

    this.getIReadyMathReportGradeLevel(this.iReadyBoyMath.grade);

    console.log("Completion Date Math", this.iReadyBoyMath?.completionDate);

    console.log("Boy Math:", this.iReadyBoyMath);
    console.log("Eoy Math:", this.iReadyEoyMath);
    console.log("Moy Math:", this.iReadyMoyMath);
  }

  assignIReadyMathReportMinMaxValue() {
    this.minScoreValueMath = Number(this.iReadyBoyMath.overallScaleScore);
    this.minScoreValueMath = this.minScoreValueMath - 40;
    this.minScoreValueMath = Math.floor(this.minScoreValueMath / 10) * 10;
    this.maxScoreValueMath = this.minScoreValueMath + 120;

    console.log("Min score Math: ", this.minScoreValueMath);
    console.log("Max score Math : ", this.maxScoreValueMath);
  }

  initializeIReadyELAChartOptions() {
    this.barChartOptions = {
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          beginAtZero: true,
          min: this.minScoreValueELA,
          max: this.maxScoreValueELA,
          ticks: {
            stepSize: this.maxScoreValueELA - this.minScoreValueELA,
          }
        }
      },
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const dataIndex = context.dataIndex;

              const scaleScore = this.barChartData[0].data[dataIndex] as number;
              const growth = this.barChartData[1].data[dataIndex] as number;

              let total = scaleScore + growth;

              //For cases where MOY or EOY are less than the minimum scale score
              if (dataIndex === 1 && this.lowerMOYELA) {
                total = this.tempOverallScoreMOYELA;
              } else if (dataIndex === 2 && this.lowerEOYELA) {
                total = this.tempOverallScoreEOYELA;
              }

              //For all the other cases, happy path workflow
              if (context.datasetIndex === 0) {
                return `Scale Score: ${total}`;
              } else if (context.datasetIndex === 1) {
                return `Growth: ${growth}`;
              }
              return '';
            }
          }
        },
        annotation: {
          annotations: {
            stretchGrowthLine: { // Stretch Growth line
              type: 'line',
              yMin: this.annualStretchGrowthMeasureELA,
              yMax: this.annualStretchGrowthMeasureELA,
              borderColor: 'grey',
              borderWidth: 2,
              borderDash: [5, 5],
              label: {
                content: 'Stretch',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            stretchGrowthValueLabel: { // Stretch Growth line label
              type: 'line',
              scaleID: 'y',
              value: this.annualStretchGrowthMeasureELA,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: this.annualStretchGrowthMeasureELA.toString(),
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            typicalGrowthLine: { // Typical Growth line
              type: 'line',
              yMin: this.annualTypicalGrowthMeasureELA,
              yMax: this.annualTypicalGrowthMeasureELA,
              borderColor: 'grey',
              borderWidth: 2,
              label: {
                content: 'Typical',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            typicalGrowthValueLabel: { // Typical Growth line label
              type: 'line',
              scaleID: 'y',
              value: this.annualTypicalGrowthMeasureELA,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: this.annualTypicalGrowthMeasureELA.toString(),
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            midOnGradeLevelLine: { // Mid On Grade Level line
              type: 'line',
              yMin: this.midOnGradeLevelELA,
              yMax: this.midOnGradeLevelELA,
              borderColor: 'green',
              borderWidth: 2,
              label: {
                content: '',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'green'
              }
            },
            midOnGradeLevelValueLabel: { // Mid On Grade Level line label
              type: 'line',
              scaleID: 'y',
              value: this.midOnGradeLevelELA,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: '',
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'green'
              }
            },
            onGradeLevelBox: { // On Grade Level green zone
              type: 'box',
              xMin: -0.5,
              xMax: 3.5,
              yMin: this.minGradeLevelELA,
              yMax: this.maxGradeLevelELA,
              backgroundColor: 'rgba(0, 128, 0, 0.2)',
              borderWidth: 0,
              borderColor: 'green',
            },
          },
        }
      }
    };
  }

  initializeIReadyMathChartOptions() {
    this.barChartOptions = {
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          beginAtZero: true,
          min: this.minScoreValueMath,
          max: this.maxScoreValueMath,
          ticks: {
            stepSize: this.maxScoreValueMath - this.minScoreValueMath,
          }
        }
      },
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const dataIndex = context.dataIndex;

              const scaleScore = this.barChartData[0].data[dataIndex] as number;
              const growth = this.barChartData[1].data[dataIndex] as number;

              let total = scaleScore + growth;

              //For cases where MOY or EOY are less than the minimum scale score
              if (dataIndex === 1 && this.lowerMOYMath) {
                total = this.tempOverallScoreMOYMath;
              } else if (dataIndex === 2 && this.lowerEOYMath) {
                total = this.tempOverallScoreEOYMath;
              }

              //For all the other cases, happy path workflow
              if (context.datasetIndex === 0) {
                return `Scale Score: ${total}`;
              } else if (context.datasetIndex === 1) {
                return `Growth: ${growth}`;
              }
              return '';
            }
          }
        },
        annotation: {
          annotations: {
            stretchGrowthLine: { // Stretch Growth line
              type: 'line',
              yMin: this.annualStretchGrowthMeasureMath,
              yMax: this.annualStretchGrowthMeasureMath,
              borderColor: 'grey',
              borderWidth: 2,
              borderDash: [5, 5],
              label: {
                content: 'Stretch',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            stretchGrowthValueLabel: { // Stretch Growth line label
              type: 'line',
              scaleID: 'y',
              value: this.annualStretchGrowthMeasureMath,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: this.annualStretchGrowthMeasureMath.toString(),
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            typicalGrowthLine: { // Typical Growth line
              type: 'line',
              yMin: this.annualTypicalGrowthMeasureMath,
              yMax: this.annualTypicalGrowthMeasureMath,
              borderColor: 'grey',
              borderWidth: 2,
              label: {
                content: 'Typical',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            typicalGrowthValueLabel: { // Typical Growth line label
              type: 'line',
              scaleID: 'y',
              value: this.annualTypicalGrowthMeasureMath,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: this.annualTypicalGrowthMeasureMath.toString(),
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            midOnGradeLevelLine: { // Mid On Grade Level line
              type: 'line',
              yMin: this.midOnGradeLevelMath,
              yMax: this.midOnGradeLevelMath,
              borderColor: 'green',
              borderWidth: 2,
              label: {
                content: '',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'green'
              }
            },
            midOnGradeLevelValueLabel: { // Mid On Grade Level line label
              type: 'line',
              scaleID: 'y',
              value: this.midOnGradeLevelMath,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: '',
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'green'
              }
            },
            onGradeLevelBox: { // On Grade Level green zone
              type: 'box',
              xMin: -0.5,
              xMax: 3.5,
              yMin: this.minGradeLevelMath,
              yMax: this.maxGradeLevelMath,
              backgroundColor: 'rgba(0, 128, 0, 0.2)',
              borderWidth: 0,
              borderColor: 'green',
            },
          },
        }
      }
    };
  }

  getIReadyELAReportGradeLevel(grade: string) {
    this.studentService
      .getStudentIReadyELAGradeLevel(this.studentId, grade)
      .subscribe((response: IStudentReportGradeLevel[]) => {
        response.forEach((item, index) => {
          console.log("Response ELA: " + response);
          console.log(`Object ${index}:`, item);

          if (item.reportLabel == "Early") {
            this.minGradeLevelELA = Number(item.lowOverall);
          }

          if (item.reportLabel == "Late") {
            this.maxGradeLevelELA = Number(item.highOverall);
          }

          if (item.reportLabel == "Mid") {
            this.midOnGradeLevelELA = Number(item.lowOverall);
          }
        });

        console.log("Min Grade Level ELA", this.minGradeLevelELA);
        console.log("Max Grade Level ELA", this.maxGradeLevelELA);
        console.log("Mid Grade Level ELA", this.midOnGradeLevelELA);

        // Initialize chart once all the chart data is loaded
        this.generateELALegends();
        this.initializeIReadyELAChartOptions();
      });
  }

  getIReadyMathReportGradeLevel(grade: string) {
    this.studentService
      .getStudentIReadyMathGradeLevel(this.studentId, grade)
      .subscribe((response: IStudentReportGradeLevel[]) => {
        response.forEach((item, index) => {
          console.log("Response Math: " + response);
          console.log(`Object ${index}:`, item);

          if (item.reportLabel == "Early") {
            this.minGradeLevelMath = Number(item.lowOverall);
          }

          if (item.reportLabel == "Late") {
            this.maxGradeLevelMath = Number(item.highOverall);
          }

          if (item.reportLabel == "Mid") {
            this.midOnGradeLevelMath = Number(item.lowOverall);
          }
        });

        console.log("Min Grade Level Math", this.minGradeLevelMath);
        console.log("Max Grade Level Math", this.maxGradeLevelMath);
        console.log("Mid Grade Level Math", this.midOnGradeLevelMath);

        // Initialize chart once all the chart data is loaded
        this.generateMathLegends();
        this.initializeIReadyMathChartOptions();
      });
  }

  getOrdinalPercentile(number) {
    const lastDigit = number % 10;

    let suffix;
    if (lastDigit === 1 && number !== 11) {
      suffix = 'st';
    } else if (lastDigit === 2 && number !== 12) {
      suffix = 'nd';
    } else if (lastDigit === 3 && number !== 13) {
      suffix = 'rd';
    } else {
      suffix = 'th';
    }

    if (number < 25)
      return number = 'Under 25th';
    else
      return number + suffix;
  }

  processApResponse(response) {
    var groups = _.groupBy(response.years, "testGroup");
    return {
      year: response.years[0].testDate.substring(0, 4),
      totalVerbal: groups["VERBAL"].reduce(
        (acc, row) => acc + parseInt(row.numberOfItems),
        0
      ),
      totalNonVerbal: groups["NONVERBAL"].reduce(
        (acc, row) => acc + parseInt(row.numberOfItems),
        0
      ),
      totalTotal: groups["TOTAL"].reduce(
        (acc, row) => acc + parseInt(row.numberOfItems),
        0
      ),
    };
  }

  processOlsatResponse(response) {
    // var groups = _.groupBy(response.years, "testGroup");
    // return {
    //   year: response.years[0].testDate.substring(0, 4),
    //   totalVerbal: groups["VERBAL"].reduce(
    //     (acc, row) => acc + parseInt(row.numberOfItems),
    //     0
    //   ),
    //   totalNonVerbal: groups["NONVERBAL"].reduce(
    //     (acc, row) => acc + parseInt(row.numberOfItems),
    //     0
    //   ),
    //   totalTotal: groups["TOTAL"].reduce(
    //     (acc, row) => acc + parseInt(row.numberOfItems),
    //     0
    //   ),
    // };
    return response
  }

  processResponse(response) {
    var rows = [];

    let ret = {
      year: "",
      rowsCount: 0,
      areas: [],
      rows: [],
    };
    for (let yearIdx in response.years) {
      let year = response.years[yearIdx];
      ret.year = year.schoolYear;
      for (let examIdx in year.pftExamList) {
        let exam = year.pftExamList[examIdx];
        ret.areas.push(exam.category);
        for (let testIdx in exam.pftTestList) {
          let test = exam.pftTestList[testIdx];
          let r = {
            area: exam.category,
            testName: test.testName,
            testValue: test.testValue,
            standardMet: 'Yes',
            hfzStandard: ''
          };
          if (r.testValue == null) {
            r.standardMet = 'No';
            r.testValue = 'N/A'
          }
          switch (r.testName) {
            case 'Curl Ups':
              r.hfzStandard = `≥ ${year.healthZone?.curlup_min || ''}`
              break;
            case 'Best VO2':
              r.hfzStandard = `≥ ${year.healthZone?.vo2_min || ''}`
              break;
            case 'Sit Reach Left':
              r.hfzStandard = `≥ ${year.healthZone?.sit_reach || ''}`
              break;
            case 'Sit Reach Right':
              r.hfzStandard = `≥ ${year.healthZone?.sit_reach || ''}`
              break;
            case 'Trunk Lifts':
              r.hfzStandard = `≥ ${year.healthZone?.trklift_min || ''}`
              break;
            case 'BMI':
              r.hfzStandard = `≤ ${year.healthZone?.bmi_min || ''}`
              break;
            case 'Push Ups':
              r.hfzStandard = `≥ ${year.healthZone?.pushup_min || ''}`
              break;
            case 'Modified Pull Ups':
              r.hfzStandard = `≥ ${year.healthZone?.pullup_min || ''}`
              break;
            case 'Fixed Arm Hang':
              r.hfzStandard = `≥ ${year.healthZone?.flex_arm_min || ''}`
              break;

            default:
              break;
          }
          rows.push(r);
        }
      }
    }
    ret.rowsCount = rows.length;
    ret.rows = rows;
    console.log(ret, 'ret===', response)
    return ret;
  }

  testDetails(testId: string) {
    this.reportId = testId;
    this.reportResponse = null;
    this.dibelsReport = null;
    this.IsDownloadButtonVisible=false;
  }

  generateAcademicYear() {
    const startingYear = parseInt(this.reportYear) - 1;
    const endingYear = parseInt(this.reportYear);
    this.academicYear = `${startingYear}-${endingYear}`;
  }
  OpenReport(){
    const data = { reportYear: this.reportYear ,iReadyTest:this.iReadyTest,studentId:this.studentId};
    const serializedData = btoa(JSON.stringify(data));
    const url = this.router.createUrlTree(['testingdownload'], { queryParams: { data: serializedData } });
  
  window.open(this.router.serializeUrl(url), '_blank');
  }
}
